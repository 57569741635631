import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()

/******* QUERY FOR INSERT DATA FROM ROOT COLLECTION *******************/
export const addDataToRootCollection = (collectionName, object, cb) => {
  db.collection(collectionName).add(object).then((doc) => cb(doc));
}

/******* QUERY FOR INSERT DATA FROM ROOT COLLECTION *******************/

export const addDataToRootCollectionWithDocKey = (collectionName,key, object, cb) => {
  const data = db.collection(collectionName).doc(key).set(object);
  cb(data);
}

/******* QUERY FOR INSERT DATA FROM ROOT COLLECTION'S SPECIFIC ID TO IT'S SUBCOLLECTION*******************/
export const addDataToRootSubCollectionWithDocKey = async (collectionName, id, subCollectionName, object, cb) => {
  const data = await db.collection(collectionName).doc(id).collection(subCollectionName).add(object);
  cb(data);
}

  