import firebase from "@/utils/firebaseInit"
import { dbCollections } from '@/utils/firebaseCollection';
const db = firebase.firestore();
import suspendActionFun from '@/utils/globalFunction/suspendActionFun';

import {blockUser} from '@/views/ComplaintManagement/service.js'


const functionForSuspendOrBlockChild = (uid,isSuspend,isfromSuspend,cb) => {
    db.collection(dbCollections.USERS).doc(uid).get().then((userRes)=>{
        if (userRes.empty) {
            cb({
                status: false,
                statusText: "No Record Found for User"
            })
        }
        if (userRes.data().hasChildren.length== 0) {
            cb({
                status: true,
                statusText: "No Child"
            })
        } 
        if (userRes.data().hasChildren.length != 0) {
            let array = []
            userRes.data().hasChildren.forEach((data)=>{
                array.push((data.id))
            })
            let count = 0
            let countFun = (row) => {
                if (count >= array.length) {
                    cb({
                        status: true,
                        statusText: "Child Update Successfully"
                    })
                   return; 
                }
                if (isSuspend == true) {
                    let updateObj = {
                        status: 2,
                        suspendUserTime: new Date(),
                        updatedAt: new Date()
                    }  
                    db.collection(dbCollections.USERS).doc(row).update(updateObj)
                    .then(()=>{
                        suspendActionFun(row)
                        .then(()=>{
                            count++;
                            countFun(array[count]);
                        })
                        .catch((error)=>{
                            count++;
                            countFun(array[count]);
                            console.error(error);
                        })
                    })
                    .catch((error)=>{
                        count++;
                        countFun(array[count]);
                        console.error(error);
                    })
                }
                if (isSuspend == false) {
                    if (isfromSuspend == true) {
                        let updateObj = {
                            status:3,
                            updatedAt: new Date()
                        }
                        db.collection(dbCollections.USERS).doc(row).update(updateObj)
                        .then(()=>{
                            blockUser({
                                uid: row
                             },() => {
                                count++;
                                countFun(array[count]);
                             })
                        })
                        .catch((error)=>{
                            count++;
                            countFun(array[count]);
                            console.error(error);
                        })
                    }
                    if (isfromSuspend == false) {
                        let updateObj = {
                            status:3,
                            updatedAt: new Date()
                        }
                        db.collection(dbCollections.USERS).doc(row).update(updateObj)
                        .then(()=>{
                            suspendActionFun(row)
                            .then(()=>{
                                blockUser({
                                    uid: row
                                 },() => {
                                    count++;
                                    countFun(array[count]);
                                 })
                            })
                            .catch((error)=>{
                                count++;
                                countFun(array[count]);
                                console.error(error);
                            })
                        })
                        .catch((error)=>{
                            count++;
                            countFun(array[count]);
                            console.error(error);
                        })
                    }
                }
            }
            countFun(array[count]);
        }
    })
}

export default functionForSuspendOrBlockChild;