import axios from 'axios'
const apiBaseURL = process.env.VUE_APP_EMAIL_SENT_API+'/api/v1/';
/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 12/07/2020, Get dynamic primary and secondry key
 */
const getAuthPrimaryOrSecondary = (version, cb) => {
    let loginUserData = JSON.parse(localStorage.getItem('currentUser'))
    axios.post(apiBaseURL+'admin/generateJwt', {
        userId:loginUserData.userDocId,
        email:loginUserData.email,
        key:'tutorline'
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(result => {
        if (result.data.status) {
            cb({
                success: true,
                token: result.data.token
            });
        } else {
            cb({});
        }
    }).catch(error => {
        console.error(error);
        cb({});
    });
};


export async function getAuth(version, cb) {
    await getAuthPrimaryOrSecondary(version, (data) => {
        cb(data);
    });
}