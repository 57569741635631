import firebase from "@/utils/firebaseInit"
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from '@/utils/firebaseCollection';
import {errorEmail} from "@/utils/apiRequests.js"
const db = firebase.firestore();

const functionForGiveWarning = (uid,key,noOfDays) => {
    return new Promise((resolve,reject) => {
            let currentDate = new Date();
            let dateCal = new Date(currentDate.setDate(currentDate.getDate() + noOfDays));
            let dateCalforUtc = dateCal.toUTCString()
            let resetDateStore = new Date(dateCalforUtc).getTime()
        try{
            const warningReset = {
                createdAt : new Date(),
                tutorId: uid,
                key: key,
                isDeleted: false,
                resetDate: resetDateStore
            }
            insertFirebase.addDataToRootCollection(dbCollections.WARNING_RESET,warningReset, (warning) => {
                if (warning) {
                   let updateObj = {
                        id : warning.id
                    }
                    updateFirebase.updateRootCollectionDataByDocId(dbCollections.WARNING_RESET,warning.id,updateObj, (creditUpdate) => {
                        resolve("Warning object created successfully")
                    })
                } else {
                    reject("Not added")
                }
            })
        }catch (error) {
            console.error(error)
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "functionForGiveWarning",
                errorMessage : error.message
            }, (mailRes) => {
                if(mailRes.error){
                    console.error(mailRes);
                }
            })
            reject(`Error :${error}`);
        }
    })
}

export default functionForGiveWarning