import { getAuth } from '../../service';
import axios from 'axios'
let apiBaseURL = process.env.VUE_APP_EMAIL_SENT_API+'/api/v1/';
const apiBaseURLV2 = process.env.VUE_APP_EMAIL_SENT_API+'/api/v2/';
// const apiBaseURL = 'http://localhost:4000/api/v1/';
export function getMethod(url, version, cb) {
    getAuth(version, (auth) => {
        if (auth && auth.success && auth.token) {
            localStorage.setItem('jwtToken', auth.token);
            const getData = async (url) => {
                if (version === 'V2') {
                    apiBaseURL = apiBaseURLV2;
                }
                axios.get(apiBaseURL+url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.token
                    }
                }).then(result => {
                    cb(result.data);
                }).catch(error => {
                    console.error(error);
                    cb({});
                });
            }
            getData(url);
        }
    })
};

export function postMethod(url, requestOptions, version, cb) {
    getAuth(version, (auth) => {
        if (auth && auth.success && auth.token) {
            localStorage.setItem('jwtToken', auth.token);

            
            const postData = async (url, requestOptions) => {
                if (version === 'V2') {
                    apiBaseURL = apiBaseURLV2;
                }
                axios.post(apiBaseURL+url, requestOptions, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.token
                    }
                }).then(result => {
                    cb(result.data);
                }).catch(error => {
                    console.error(error);
                    cb({});
                });
            }
            postData(url, requestOptions);
        }
    })
};