import firebase from "@/utils/firebaseInit"
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from '@/utils/firebaseCollection';
import {errorEmail} from "@/utils/apiRequests.js"
const db = firebase.firestore();
import {sendNotificationOnCancellBookingForUserSuspendOrBlock} from "./bookingCancelNotificationservice.js"
import {transectionNotificatoinOnAmountOrCredit} from"./transectionAmountNotificationService.js"
import {onBookingCancelUpdateSlot} from "./updateSlotWhenCancelBookingService.js"
const functionForSuspendtionAction = (uid)=>{
    return new Promise(async (resolve, reject) => {
        try {
            let tutorSession = [];
            const sessionTutors = await db.collection(dbCollections.BOOKING)
                                            .where("startTime", ">", new Date())
                                            .where("tutor","==",uid)
                                            .where("status",'in',[0,1,2])
                                            .get();
            
            sessionTutors.forEach((tutorSessionDoc) => {
                    let obj = tutorSessionDoc.data()
                    obj.userRole = "Tutor"
                tutorSession.push(obj);
            });
            const sessionLearners = await db.collection(dbCollections.BOOKING)
                                            .where("startTime", ">", new Date())
                                            .where("learnerId","==",uid)
                                            .where("status",'in',[0,1,2])
                                            .get();
                                            
            sessionLearners.forEach((sessionDoc) => {
                let obj = sessionDoc.data()
                obj.userRole = "Learner"
                tutorSession.push(obj);
            });
            const sessionChild = await db.collection(dbCollections.BOOKING)
                                        .where("startTime", ">", new Date())
                                        .where("childId","==",uid)
                                        .where("status",'in',[0,1,2])
                                        .get();
            sessionChild.forEach((sessionDoc) => {
                let obj = sessionDoc.data()
                obj.userRole = "Child"
                tutorSession.push(obj);
            });
            if(tutorSession.length === 0){
                getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.WARNING_RESET,"tutorId","==",uid, (warning)=> {
                    if (warning.length === 0) {
                        return;  
                    } else {
                        warning.forEach((data)=> {
                         let updateObj = {
                                isDeleted : true
                            }
                            updateFirebase.updateRootCollectionDataByDocId(dbCollections.WARNING_RESET,data.id,updateObj,(update)=> {
                            })   
                        })
                    }
                })     
                resolve("No data Found for future session");
            } else {
                let isCompleted = false;
                let count = 0;
                if(isCompleted === false) {
                    const countFun = async (rowData) => {
                        if (count >= tutorSession.length) {
                            isCompleted = true;
                            // self.isSpinner = false;
                            getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.WARNING_RESET,"tutorId","==",uid, (warning)=> {
                                if (warning.length === 0) {
                                    count++;
                                    countFun(tutorSession[count]);
                                    return;  
                                } else {
                                    warning.forEach((data)=> {
                                        let updateObj = {
                                            isDeleted : true
                                        }
                                        updateFirebase.updateRootCollectionDataByDocId(dbCollections.WARNING_RESET,data.id,updateObj,(update)=> {
                                        })   
                                    })
                                }
                            })       
                            resolve("Function called Successfully")
                        }else{  
                            console.log("count",count)
                            const docId = rowData.learnerId != undefined ? rowData.learnerId : "";
                            if(docId === "") {
                                count++;
                                countFun(tutorSession[count]);
                            } else {
                                getFirebase.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,docId, async (resss)=>{
                                    if(resss.empty){
                                        count++;
                                        countFun(tutorSession[count]);
                                    }else{
                                        let transactionUser  = (resss.isChild == true) ? hasParent.id : docId
                                        await db.collection(dbCollections.CREDITTRANSACTIONS)
                                                .where("userId","==",transactionUser)
                                                .orderBy("createdAt", "desc")
                                                .limit(1)
                                                .get()
                                                .then(async(docData) => {
                                                    if(docData.empty){
                                                        count++;
                                                        countFun(tutorSession[count]);
                                                    } else {
                                                        let sengleBookingData = docData.docs[0].data();                                         
                                                        let updateObject = {
                                                            status: 3,
                                                            updatedAt : new Date(),
                                                            cancelReasonStatus: Number(2),
                                                            usedCredits : 0,
                                                            cancellationReason: "Due to suspend by the admin",
                                                            cancellationDate: new Date()
                                                        }
                                                        await updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,rowData.id,updateObject,(bookingUpdate) => {
                                                            onBookingCancelUpdateSlot({
                                                                bookingId: rowData.id
                                                            },()=>{})
                                                            if(bookingUpdate === false){
                                                                count++;
                                                                countFun(tutorSession[count]);
                                                                return;
                                                            }
                                                            if (rowData.userRole == "Tutor") {
                                                                sendNotificationOnCancellBookingForUserSuspendOrBlock({
                                                                    bookingId: rowData.bookingId,
                                                                    isLearner: false,
                                                                    userId: rowData.learnerId,
                                                                },(resp)=>{console.log(resp)})
                                                                // transectionNotificatoinOnAmountOrCredit({
                                                                //     isChild : false,
                                                                //     value : rowData.usedCredits,
                                                                //     isCredited: true,
                                                                //     transactionType: "Credit",
                                                                //     userId: rowData.learnerId
                                                                // },(resp)=>{console.log(resp)})
                                                            }
                                                            if (rowData.userRole == "Learner") {
                                                                sendNotificationOnCancellBookingForUserSuspendOrBlock({
                                                                    bookingId: rowData.bookingId,
                                                                    isLearner: true,
                                                                    userId: rowData.tutor,
                                                                },(resp)=>{console.log(resp)})
                                                                // transectionNotificatoinOnAmountOrCredit({
                                                                //     isChild : false,
                                                                //     value : rowData.usedCredits,
                                                                //     isCredited: true,
                                                                //     transactionType: "Credit",
                                                                //     userId: rowData.tutor
                                                                // },(resp)=>{console.log(resp)})
                                                            }
                                                            if (rowData.userRole == "Child") {
                                                                sendNotificationOnCancellBookingForUserSuspendOrBlock({
                                                                    bookingId: rowData.bookingId,
                                                                    isLearner: true,
                                                                    userId: rowData.tutor,
                                                                },(resp)=>{console.log(resp)})
                                                                // transectionNotificatoinOnAmountOrCredit({
                                                                //     isChild : true,
                                                                //     childName: rowData.childDetail.firstName +" "+ rowData.childDetail.lastName,
                                                                //     value : rowData.usedCredits,
                                                                //     isCredited: true,
                                                                //     transactionType: "Credit",
                                                                //     userId: rowData.tutor
                                                                // },(resp)=>{console.log(resp)})
                                                            }
                                                            
                                                            const learnerDeatil = {
                                                                firstName :resss.firstName?resss.firstName:'',
                                                                lastName : resss.lastName?resss.lastName:'',
                                                                profileImage:resss.profilePicture?resss.profilePicture:''
                                                            }
                                                            const transactionObject = {
                                                                availableCredit:Number(sengleBookingData.availableCredit?sengleBookingData.availableCredit:0) + Number(rowData.usedCredits?rowData.usedCredits:0),
                                                                bookingId:rowData.id,
                                                                createdAt:new Date(),
                                                                credits:Number(rowData.usedCredits?rowData.usedCredits:0),
                                                                id:'',
                                                                paymentId:'',// This feild is static
                                                                reference:1,
                                                                remarks:'Booking cancelled.',
                                                                transactionType:"cr",
                                                                userDetail:learnerDeatil,
                                                                userId: transactionUser,
                                                                isSendNotification: false
                                                            }
                                                            insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,async(credit) => {
                                                                if(credit && credit.id !== ''){
                                                                    const updateObj = {
                                                                        id : credit.id
                                                                    }
                                                                    await updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,credit.id,updateObj,async(creditUpdate) => {
                                                                        if(creditUpdate === false){
                                                                            count++;
                                                                            countFun(tutorSession[count]);
                                                                            return;
                                                                        }
                                                                        const updateObj = {
                                                                            currentCredit : Number(resss.currentCredit?resss.currentCredit:0) + Number(rowData.usedCredits?rowData.usedCredits:0),
                                                                            // warning : 0
                                                                        }
                                                                        await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,transactionUser,updateObj,async(res) => {
                                                                            if(!res){
                                                                                count++;
                                                                                countFun(tutorSession[count]);
                                                                                return;
                                                                            }
                                                                            count++;
                                                                            countFun(tutorSession[count]);
                                                                        })
                                                                    })
                                                                }else{
                                                                    count++;
                                                                    countFun(tutorSession[count]);
                                                                }
                                                            })
                                                        })  
                                                    }
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            count++;
                                            countFun(tutorSession[count]);
                                        })
                                    }
                                })    
                            }
                        }
                    };
                    countFun(tutorSession[count]);
                }
            }
        }
        catch (error) {
            console.error(error)
            reject(`Error :${error}`);
        }
    })
}

export default functionForSuspendtionAction;