import firebase from "@/utils/firebaseInit";

const storageRef = firebase.storage().ref()
export const uploadImageFileWithoutUniqueString = function(folderName,imageName,file,cb)
{
    var str = (
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8)
    ).toUpperCase();
    
    var uploadTask = storageRef.child(folderName+"/"+imageName).put(file)
    uploadTask.on('stateChanged',
    (snapshot)=>{
        var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
    },
    (error)=>{
        console.error(error)
    },
    ()=>{
        uploadTask.snapshot.ref.getDownloadURL()
        .then(URL=>{
            cb({imageUrl:URL,imageName:imageName})    
        })
    })
}
export const uploadImageFile = function(folderName,imageName,file,cb)
{
    var ImageNameSplit = imageName.split('.')
    var str = (
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8)
    ).toUpperCase();
    var ImageName=""
    if(ImageNameSplit.length>2)
    {
        for (let index = 0; index < ImageNameSplit.length-1; index++) {
            ImageName += `${ImageNameSplit[index]}`
        }
        ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
    }
    else
    {
        ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
    }
    
    var uploadTask = storageRef.child(folderName+"/"+ImageName).put(file)
    uploadTask.on('stateChanged',
    (snapshot)=>{
        var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
    },
    (error)=>{
        console.error(error)
    },
    ()=>{
        uploadTask.snapshot.ref.getDownloadURL()
        .then(URL=>{
            cb({imageUrl:URL,imageName:ImageName})    
        })
    })
}

export const uploadImageFiles = function(folderName,imageName,file,cb)
{
    var ImageNameSplit = imageName.split('.')
    var str = (
        Math.random().toString(36).substring(2, 8) +
        Math.random().toString(36).substring(2, 8)
    ).toUpperCase();
    var ImageName=""
    if(ImageNameSplit.length>2)
    {
        for (let index = 0; index < ImageNameSplit.length-1; index++) {
            ImageName += `${ImageNameSplit[index]}`
        }
        ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
    }
    else
    {
        ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
    }
    
    var uploadTask = storageRef.child(folderName+"/"+ImageName).put(file)
    uploadTask.on('stateChanged',
    (snapshot)=>{
        var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
    },
    (error)=>{
        this.$root.$emit('hidePreviewPoll')
        this.$notify({
            title:"Error",
            text:error.message,
            type:"error"
        })
    },
    ()=>{
        uploadTask.snapshot.ref.getDownloadURL()
        .then(downloadUrl=>{
            cb({imageUrl:downloadUrl,imageName:ImageName})    
        })
    })
}

/*************************** REMOVE IMAGE FROM FIREBASE STORAGE ********************************/
export const removeImage = (folderName, imageName, callback) => {
    var desertRef = storageRef.child(folderName+"/"+imageName);  
    if(desertRef){
    desertRef.delete().then(function() {
      callback(true);
    })
    .catch(function(error) {
        callback(error);
    });
    }else{
        callback(false);
    }
  }

  
/*************************** DOWNLOAD FILE FROM FIREBASE STORAGE ********************************/
export const downloadFile = (folderName, imageName, callback) => {
    storageRef.child(folderName+'/'+imageName).getDownloadURL().then((url) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = url
    a.download = 'file'
    a.target="_blank"
    a.click()
    callback(a)
 }).catch(function(msg) {
    callback(msg)
 })
}

/**********************************REMOVE IMAGE THROUGH URL*****************************************/
export const removeImageThroughURL = (imageUrl,callback) =>{
    const storage = firebase.storage();
    var fileRef = storage.refFromURL(imageUrl);    
    try {
        fileRef.delete().then(function () {        
            callback("DONE")
        }).catch(function (error) {
            callback(error)
        });      
    } catch (e) {
        callback(msg)
    }
}