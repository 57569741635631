import { postMethod } from '../regenerateAuth'
import { getAuth } from '../../../service'
import axios from 'axios'
const apiBaseURL = process.env.VUE_APP_EMAIL_SENT_API+'/api/v1/';



export async function onWarningGiveNotification(requestOptions, cb) {
    if (!localStorage.getItem('jwtToken')) {
        getAuth('', async (auth) => {
            if (auth && auth.success && auth.token) {
                localStorage.setItem('jwtToken', auth.token);
                axios.post(apiBaseURL+'onWarningGiveNotification', requestOptions, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
                    }
                }).then(result => {
                    if (result.data.success === false) {
                        postMethod('onWarningGiveNotification', requestOptions, '', (resData) => {
                            cb(resData);
                        });
                        return;
                    }
                    cb(result.data);
                }).catch(error => {
                    console.error(error);
                    cb({
                        errorMessage: error
                    });
                });
            }
        });
    } else {
        axios.post(apiBaseURL+'onWarningGiveNotification', requestOptions, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
                
            }
        }).then(result => {
            if (result.data.success === false) {
                postMethod('onWarningGiveNotification', requestOptions, '', (resData) => {
                    cb(resData);
                });
                return;
            }
            cb(result.data);
        }).catch(error => {
            console.error(error);
            cb({
                errorMessage: error
            });
        });
    }
};
